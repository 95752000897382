p {
  margin: 0;
  padding: 0;
}
.first-screen {
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(to bottom right, #8e2de2, #69aedb);
  /* background: #69aedb; */
  padding: 30px 220px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.first-screen .logo {
  width: 60px;
  /* height: 80px; */
}

.first-screen-content {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background: url("../images/banner2.png") right center no-repeat;
  background-size: 550px auto;
}
.first-screen-content > div {
  flex: 1;
}

.first-screen-content .left {
  h1 {
    font-size: 40px;
  }
  h3 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 14px;
  }
  margin-bottom: 100px;
}

.first-screen-content .right {
  text-align: right;
}

.banner {
  height: 400px;
}

.btn {
  margin-top: 30px;
  padding: 14px 40px;
  width: 120px;
  border-radius: 10px;
  background-color: #fff;
  color: #1f277a;
  text-align: center;
  font-size: 18px;
}

.data-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  width: 90%;
  align-self: center;
}

.data-item {
  flex: 1;
  color: #fff;
  text-align: center;
}
.data-value {
  font-weight: bolder;
  font-size: 24px;
}

.seperation {
  width: 1px;
  height: 100%;
  background-color: #cdcdcd;
  border-radius: 1px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -80px;
}

.header-right {
  display: flex;
  align-items: center;
  color: #fff;
  .menu-item {
    width: 100px;
    cursor: pointer;
  }
}

.service {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  padding: 20px;
  background-color: #f7f7f7;
  &.right {
    flex-direction: row-reverse;
    background-color: #fff;
    .img-wrap {
      margin-right: 200px;
      margin-left: 0;
    }
  }

  .service-content {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    width: 200px;
    height: 300px;
    margin-top: 20px;

    h2 {
      font-size: 22px;
      margin-bottom: 12px;
    }
    p {
      margin-bottom: 6px;
    }
  }

  .img-wrap {
    margin-left: 200px;
    width: 300px;
    height: 300px;
    background-color: #f3fafd;
    border-radius: 10px;
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.5);
    img {
      width: 260px;
    }
  }
}
